@import "./assets/styles/colors";
@import "./assets/styles/regular";

.App {
  text-align: center;
  font-family: 'Inter-Regular';
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  font-size: 16px;
}

@media only screen and (max-width: 1024px) {
  .App{
    font-size: 12px;
  }
  .modal{
    font-size: 12px;
  }
  .swal2-container{
    font-size: 10px;
  }
  .search_container{
    padding: 10px;
  }
  .category{
    margin: 10px;
  }
  .items{
    padding: 10px;
  }
}

@media only screen and (max-width: 960px) {
  .App{
    font-size: 10px;
  }
  .modal{
    font-size: 10px;
  }
  .swal2-container{
    font-size: 8px;
  }
}

@media only screen and (max-width: 768px) {
  .App{
    font-size: 8px;
  }
  .modal{
    font-size: 8px;
  }
  .swal2-container{
    font-size: 6px;
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.row{
  margin-right: 0 !important;
  margin-left: 0 !important;
}

/* inter-regular - latin */
@font-face {
  font-family: 'Inter-Regular';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/inter-v12-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./assets/fonts/inter-v12-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/inter-v12-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/inter-v12-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/inter-v12-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/inter-v12-latin-regular.svg#Inter') format('svg'); /* Legacy iOS */
}
/* inter-500 - latin */
@font-face {
  font-family: 'Inter-Medium';
  font-style: normal;
  font-weight: 500;
  src: url('./assets/fonts/inter-v12-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./assets/fonts/inter-v12-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/inter-v12-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/inter-v12-latin-500.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/inter-v12-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/inter-v12-latin-500.svg#Inter') format('svg'); /* Legacy iOS */
}
/* inter-600 - latin */
@font-face {
  font-family: 'Inter-SemiBold';
  font-style: normal;
  font-weight: 600;
  src: url('./assets/fonts/inter-v12-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./assets/fonts/inter-v12-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/inter-v12-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/inter-v12-latin-600.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/inter-v12-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/inter-v12-latin-600.svg#Inter') format('svg'); /* Legacy iOS */
}
/* inter-700 - latin */
@font-face {
  font-family: 'Inter-Bold';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/inter-v12-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./assets/fonts/inter-v12-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./assets/fonts/inter-v12-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('./assets/fonts/inter-v12-latin-700.woff') format('woff'), /* Modern Browsers */
       url('./assets/fonts/inter-v12-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./assets/fonts/inter-v12-latin-700.svg#Inter') format('svg'); /* Legacy iOS */
}